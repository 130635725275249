import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/headerinvest"

const InvestInRwanda = () => (
  <Layout>
    <SEO title="Invest in Rwanda" />

    <Header />

    <section className="section bg-primary">
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col">
            <h1 className="h2 my-2 font-weight-bold text-light">
              INVEST IN RWANDA
            </h1>
          </div>
          <div className="col-auto">
            <a className="btn btn-sm btn-outline-light">
              Where can you invest?
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className="section py-8  px-8 pb-9">
      <div className="container-fluid">
        <div className="py-8">
          <div className="row row-grid justify-content-between align-items-center">
            <div className="col-lg-6 order-lg-2">
              <h5 className="h3 font-weight-bold text-primary text-uppercase">
                {" "}
                Get to know Rwanda!
              </h5>
              <p className=" my-4">
                The Republic of Rwanda has a population of over 12 Million
                people. It is located in east Africa. Rwanda lies on 26,338 sqm
                and its capital city is Kigali. The time zone is UTC +2 (Central
                Africa Time). The common languages used in Rwanda include:
                English, French, Kinyarwanda and Kiswahili. The currency used in
                Rwanda is the Rwandan franc. Rwanda’s GDP per capita is $787
                while its GDP growth per annum is over 8%. The climate temperate
                all year round is (21-27 degrees Celsius).
              </p>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="card mb-0 mr-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("./../images/downloads/RY6B0946.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-8">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <p className="text-center text-primary lead font-weight-bold">
                VISIT RWANDA
              </p>
              <p className="my-4">
                Kigali is the cleanest city in Africa. Tourists get to enjoy the
                fresh clean breeze from the green grassland on the sides of
                almost every highway. The beautiful scenery, the precipitous and
                hilly nature, the fresh chilly breeze hovering in the atmosphere
                is undeniably breath taking.
              </p>
              <p className="my-4">
                You must have thought about your safety before considering
                investing in Rwanda. There are no security risks involved.
                Rwanda is one of the safest countries in Africa with the least
                reports of crime rates. You can walk on the streets at any
                desired hour of night or day!
              </p>
              <p className="my-4">
                The thick bamboo forests of Rwanda are home to three thirds of
                the world’s remaining mountain gorillas. Rwanda also harbors
                over three hundred bird species, Columbus monkeys and
                chimpanzees. Visit Rwanda’s Akagera National Park and the big
                five of Africa, lions etc.
              </p>
              <p className="my-4">
                Rwanda harbors five to eight fire breathing peaks known as the
                Virunga ranges. Karisimbi is the highest of the ranges and is
                snow-capped even in the annual dry seasons. Tourists get to
                experience a great adventure on a two-day’s hike on this
                inactive volcano.
              </p>
              <p className="my-4">
                <a target="_blank" href="https://www.visitrwanda.com/">https://www.visitrwanda.com/</a>
              </p>
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="row  row-grid justify-content-center align-items-center">
            <div className="col-auto col-lg-10">
              <h5 className="h2 text-center font-weight-bold text-primary">
              ECONOMIC GROWTH
              </h5>
              <p className=" my-4">
                According to a report by Rwanda Development Board, Rwanda’s
                economy has grown at an annual GDP rate of 8% on the average in
                the past one decade.
              </p>
              <p className=" my-4">
                The service sector contributes up to 48% and has been the
                largest GDP contributor. Other great contributors are the
                Agriculture 29% and the industrial sector 16% respectively.
                Rwanda’s inflation has kept at one single digit since 2008.
              </p>
              <p className="my-4">
                Rwanda has earned high ratings from Fitch ratings. Fitch
                affirmed Rwanda’s B+ ratings for its great governance, low
                public debt, business metrics and high growth potential. The
                country has also taken the lead for top global reformer in the
                annual World Bank business reports.
              </p>
              <p className="my-4">
                Rwanda has also scored 50.94/100 points on the global
                competitiveness report 2018. The report was published by the
                world economic forum. Rwanda’s competitive index earned an
                average of 15.72 points from 2011 to 2018.
              </p>
              <p className="my-4">
                Rwanda has maintained competitiveness in terms of efficient
                goods, labor markets, political stability and numerous efforts
                towards great GDP growth. Based on Rwanda development board
                reports read more:{"  "}
                <a href="https://rdb.rw/" className="btn-link">
                  https://rdb.rw/
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default InvestInRwanda
